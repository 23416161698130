import styled from 'styled-components';
import Slider from 'react-slick';
import {COLORS} from '../../constants';
import {MainContainer} from '../../styled';

export const StartContainer = styled(MainContainer)`
  display: flex;
  align-items: center;
`;

export const StyledSlider = styled(Slider)`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  
  .slick-slide {
    display: flex;
    justify-content: center;
  }
  
  img, div {
    width: 100vw;
    height: 100%;
  }
  
  img {
    opacity: 1;
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.75);
  }
  
  div {
    //border-bottom: 2px solid #111;
  }


  &:hover {
    transition: all 0.2s;
    filter: brightness(120%);
  }

  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    img, div {
      width: 100vw;
      height: 85%;
    }
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) and (orientation : landscape) {
    img, div {
      width: 100vw;
      height: 100%;
    }
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    img, div {
      width: 100vw;
      height: 85%;
    }
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    img, div {
      width: 100vw;
      height: 100%;
    }
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    img, div {
      width: 100vw;
      height: 100%;
    }
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    img, div {
      width: 100vw;
      height: 85%;
    }
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
    img, div {
      width: 100vw;
      height: 100%;
    }
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
    img, div {
      width: 100vw;
      height: 100%;
    }
  }
`;

export const BoxesRow = styled.section`
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;

  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    flex-direction: row;
    font-size: 0.6em;
    padding-top: 40%;
    margin-bottom: 50px;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) {
    flex-direction: row;
    font-size: 0.6em;
    margin-bottom: 50px;
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    flex-direction: row;
    font-size: 0.6em;
    margin-bottom: 50px;
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    flex-direction: row;
    margin: 0;
    font-size: 0.8em;
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    flex-direction: row;
    font-size: 0.8em;
    margin: 0;
    padding-top: 10%;
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    flex-direction: row;
    font-size: 0.8em;
    margin: 0;
    padding-top: 40%;
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
    flex-direction: row;
    font-size: 1.2em;
    margin: 0;
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
    flex-direction: row;
    font-size: 1.2em;
    margin: 0;
  }
`;

export const Box = styled.section`
  position: relative;
  overflow: hidden;
  display: flex;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.75);
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: ${COLORS.brown2};
  font-weight: 800;
  background: #222;
  width: 250px;
  height: 250px;
  border: 1px solid #333;
  transition: all .2s;

  p {
    z-index: 10;
  }

  &:hover {
    transition: all .2s;
    background: #333;
  }

  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    height: 100px;
    width: 100px;
    font-size: 1.0em;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) and (orientation : landscape) {
    height: 100px;
    width: 100px;
    font-size: 1.0em;
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    height: 100px;
    width: 100px;
    font-size: 1.0em;
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    height: 200px;
    width: 200px;
    font-size: 1.3em;
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    margin-bottom: 120px;
    height: 160px;
    width: 160px;
    font-size: 1.3em;
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    height: 200px;
    width: 200px;
    font-size: 1.3em;
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
    width: 300px;
    font-size: 1.2em;
    height: 300px;
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
    width: 300px;
    font-size: 1.3em;
    height: 300px;
  }
`;

export const Slogan = styled.div`
  z-index: 100;
  width: 100%;
  min-height: 230px;
  color: ${COLORS.brown2};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.0em;
  background: rgba(30, 30, 30, 1);
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.75);
  padding: 0 20px;
  box-sizing: border-box;


  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: 100%;
    font-size: 0.7em;
    div {
      flex-direction: column;
      align-items: center;
    }

    img {
      width: 100px;
      height: auto !important;
    }
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) and (orientation : landscape) {
    width: 100%;
    min-height: 240px;
    font-size: 0.7em;
    div {
      flex-direction: column;
      align-items: center;
    }

    img {
      width: 100px;
      height: auto !important;
    }
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    width: 100%;
    min-height: 210px;
    font-size: 0.7em;
    div {
      flex-direction: column;
      align-items: center;
    }

    img {
      width: 100px;
      height: auto !important;
    }
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    font-size: 1.0em;
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    font-size: 1.0em;
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    font-size: 1.0em;
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
    font-size: 1.0em;
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
    font-size: 1.0em;
  }
`;

export const SloganItem = styled.div`
  display: flex; 
  margin: 25px 5px 20px;
  justify-content: space-between;
  width: 500px;

  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: 100%;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) and (orientation : landscape) {
    width: 100%;
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    width: 100%;
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
  }
`;

export const FacebookContainer = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  background: #4267B2;
  font-size: 60px;
  text-decoration: none;
  color: #FFF;
  transition: all 0.2s;

  &:hover {
    transition: all 0.2s;
    filter: brightness(120%);
  }



  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    font-size: 30px;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) {
    font-size: 30px;
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    font-size: 30px;
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    font-size: 40px;
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    font-size: 40px;
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    font-size: 40px;
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
    font-size: 60px;
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
    font-size: 60px;
  }
`;

export const InstagramContainer = styled.a`
  color: #FFF;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  width: 50%;
  font-size: 60px;
  height: 100%;
  background: #f09433; 
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
  background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  
  transition: all 0.2s;

  &:hover {
    transition: all 0.2s;
    filter: brightness(120%);
  }

  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    font-size: 30px;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) {
    font-size: 30px;
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    font-size: 30px;
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    font-size: 40px;
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    font-size: 40px;
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    font-size: 40px;
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
    font-size: 60px;
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
    font-size: 60px;
  }
`;

export const BoxBackground = styled.img`
  position: absolute;
  z-index: 5;
  opacity: 0.1;
  heigth: 100%;
  // max-height: 300px;
  // width: 100%;
`;

export const Container = styled.div`
  padding: ${({p}) => p || '50px 10% 50px'};
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;

  a {
    color: ${COLORS.black};
  }
  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    padding: ${({p}) => p || '30px 3% 30px'};
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) {
    padding: ${({p}) => p || '30px 3% 30px'};
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    padding: ${({p}) => p || '30px 3% 30px'};
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    padding: ${({p}) => p || '30px 3% 30px'};
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    padding: ${({p}) => p || '30px 3% 30px'};
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    padding: ${({p}) => p || '30px 3% 30px'};
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
    padding: ${({p}) => p || '30px 3% 30px'};
  }
`;

export const Heading = styled.h1`
  color: ${COLORS.white};
  font-size: 24px;
  margin-bottom: 10px;
`;

export const Subheading = styled.h2`
  color: ${COLORS.white};
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const Paragraph = styled.p`
  color: ${COLORS.white};
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
`;

export const List = styled.ul`
  list-style-type: disc;
  margin: 10px 0 10px 20px;
  padding: 0;
`;

export const ListItem = styled.li`
  margin-bottom: 5px;
  color: ${COLORS.white};
  font-size: 16px;
`;

export const StartContainerWrapper = styled.div`
  z-index: 999;
  margin-top: 107px;
  width: 100%;
  height: 50vh;
  background: linear-gradient(to bottom, rgba(0,0,0,0.9), rgba(0,0,0,0.0));
  display: flex;
  justify-content: center;

  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    height: 30vh;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) {
    height: 28vh;
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    height: 30vh;
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    height: 30vh;
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    height: 30vh;
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    height: 30vh;
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
  }
`;

export const InnerStartContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 180px;
  width: 100%;

  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    display: none;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) {
    display: none;
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    display: none;
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    display: none;
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    display: none;
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    display: none;
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
  }
`;

export const StartSection = styled.div`
  background: ${COLORS.brown};
  border-bottom: 1px solid ${COLORS.brown};
  z-index: 999;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${({height}) => height || '800px'};

  div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    flex-direction: ${({flexDir}) => {
      return flexDir || 'column';
    }};
    height: auto;

    img {
    }
    div {
      height: auto;
    }
  }
  
  /* Smartphones (landscape) ----------- */
@media only screen and (min-width : 321px) and (max-width : 1024px) {
    flex-direction: ${({flexDir}) => {
      return flexDir || 'column';
    }};
    height: auto;
     
    img {
    }
    div {
      height: auto;
    }
  }
  
  /* Smartphones (portrait) ----------- */
    @media only screen and (max-width : 320px) {
    flex-direction: ${({flexDir}) => {
      return flexDir || 'column';
    }};
    height: auto;
      
    img {
    }
    div {
      height: auto;
    }
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    flex-direction: ${({flexDir}) => {
      return flexDir || 'column';
    }};
    height: auto;
     
    img {
    }
    div {
      height: auto;
    }
  }
  
  /* iPads (landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    flex-direction: ${({flexDir}) => {
      return flexDir || 'column';
    }};
    height: auto;
     
    img {
    }
    div {
      height: auto;
    }
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    flex-direction: ${({flexDir}) => {
      return flexDir || 'column';
    }};
    height: auto;
     
    img {
    }
    div {
      height: auto;
    }
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
  }
`;
