import React from 'react';
import {useHistory} from 'react-router-dom';
import {COLORS, ROUTES} from '../../constants';
import LOGO from '../../assets/logowhite.png';
import img01 from '../../assets/slideshow/img01.jpg';
import {BookButton} from '../../styled';
import Collapsible from 'react-collapsible';
import styled from 'styled-components';
import beforeAfterImg from '../../assets/images/beforeafter1.png';
import pic01 from '../../assets/images/pic1.png';
import mainImg from '../../assets/images/new/IMG_9243.jpeg';

import {
  TreatmentContainer,
  TreatmentTitle,
  TreatmentDescription,
  LogoBookButtonWrapper,
} from '../../styled';

const CollapsibleLabel = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.2em;
  padding: 0 10px 0;
  border: 1px solid ${COLORS.gold};
  width: 100%;
  height: 50px;
  cursor: pointer;
  margin: 5px 0;

  // media queries
//  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    padding: 0 25px 0;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) and (orientation : landscape) {
    padding: 0 25px 0;
  }
 
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    padding: 0 25px 0;

  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    padding: 0 25px 0;

  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    padding: 0 25px 0;

  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    padding: 0 25px 0;

  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {

  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {

  }
  
`;

const OpenLabel = styled.div`
  right: 10px;
  position: absolute;
  font-size: 1.5em;
`;

const Label = ({children, isOpen}) => {
  return (
    <CollapsibleLabel>
      {children}
      {isOpen 
        ? <OpenLabel>-</OpenLabel>
        : <OpenLabel>+</OpenLabel>
      }
    </CollapsibleLabel>
  );
};

const HairRemoval = () => {
  const history = useHistory();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [open, setOpen] = React.useState({});

  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div style={{marginTop: '100px', position: 'relative', width: '100%', height: '50vh', background: COLORS.black}}>
          <img
            src={mainImg} alt="Image of table with Sax and the City text"
            style={{width: '100%', height: '100%', objectFit: 'cover', filter: 'brightness(80%)'}}
          />
        </div>
        <LogoBookButtonWrapper>
          <img src={LOGO} style={{width: '50%'}} />
          <BookButton onClick={() => history.push(ROUTES.bookings)}>Boka nu</BookButton>
        </LogoBookButtonWrapper>
      </div>
      <TreatmentContainer>
        <div>
          <TreatmentTitle>Hårborttagning Malmö med laser</TreatmentTitle>
          <h3 style={{padding: '0 15px 0'}}>50% rabatt för nya kunder!</h3>
          <TreatmentDescription>
            Upplever du oönskad hårväxt och är trött på att raka dig varannan dag, vilket resulterar i inåtväxande hårstrån, tårar och hudirritation? Vill du undvika smärtsamma och tidskrävande behandlingar som epilering och vaxning? Utöver detta kommer även den ekonomiska kostnaden för dyra skrapor och den miljöpåverkan som följer med att använda skrapor under en livstid.
          </TreatmentDescription>
          <TreatmentDescription>
            Hårborttagning med laser är en lämplig behandling för permanent hårborttagning eller reducering av oönskad hårväxt.
          </TreatmentDescription>
          <TreatmentDescription>
            De flesta hud och hårtyper går att behandla med diodlaser ifall hårsäcken är mörkare än huden. Men vitt, grått och rött hår kommer inte att försvinna med laser.
          </TreatmentDescription>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column'}}>
          <Collapsible onClose={() => setOpen({one: false})} onOpen={() => setOpen({one: true})} trigger={<Label isOpen={open['one']}>Gör det ont?</Label>}>
            <TreatmentDescription>
              Även om processen kan låta smärtsam, oroa dig inte – den är i stort sett helt smärtfri. Vi använder en lasermaskin med kylfunktion för att säkerställa din komfort under behandlingen.
            </TreatmentDescription>
          </Collapsible>
          <Collapsible onClose={() => setOpen({two: false})} onOpen={() => setOpen({two: true})} trigger={<Label isOpen={open['two']}>Hur lång tid måste det gå mellan mina behandlingar?</Label>}>
            <TreatmentDescription>
              Mellan varje behandling måste det gå mellan 4-8 veckor. Detta tidsintervall säkerställer optimal effektivitet och möjliggör en gradvis minskning av hårväxten.
            </TreatmentDescription>
          </Collapsible>
          <Collapsible onClose={() => setOpen({three: false})} onOpen={() => setOpen({three: true})} trigger={<Label isOpen={open['three']}>hur många behandlingar behöver jag?</Label>}>
            <TreatmentDescription>
              Beroende på det behandlade området rekommenderar vi minst 6-8 behandlingar. Även om vissa kliniker lovar färre behandlingar är detta ofta orealistiskt.
            </TreatmentDescription>
            <TreatmentDescription>
              Hårväxt sker i 4 faser: tidig anagen (tidig hårväxt), anagen (aktiv hårväxt), catagen (regression) och telogen (vilar, växer inte). Hårborttagning med laser tar endast bort aktivt växande hår, varför flera behandlingar är nödvändiga för att säkerställa fullständig effekt.
            </TreatmentDescription>
          </Collapsible>
          <Collapsible onClose={() => setOpen({four: false})} onOpen={() => setOpen({four: true})} trigger={<Label isOpen={open['four']}>Vem kan inte få permanent hårborttagning gjort?</Label>}>
            <TreatmentDescription>
              Allmänna kontraindikationer, tillstånd som talar emot permanent hårborttagningsbehandling, inkluderar:
              <ul>
                <li>Gravida och ammande kvinnor.</li>
                <li>Människor i behandling för akne.</li>
                <li>Epilepsipatienter.</li>
                <li>Individ med pacemaker.</li>
                <li>Cancerpatienter.</li>
                <li>Personer med aktiv herpesinflammation i det avsedda behandlingsområdet.</li>
                <li>Människor med okontrollerade hormonella störningar.</li>
              </ul>
            </TreatmentDescription>
            <TreatmentDescription>
              Dessa kontraindikationer måste övervägas för att säkerställa säkerheten och hälsan för dem som överväger permanent hårborttagning. Det är viktigt att konsultera en professionell terapeut för att avgöra om behandlingen är lämplig och säker för individen.
            </TreatmentDescription>
          </Collapsible>
          <Collapsible onClose={() => setOpen({five: false})} onOpen={() => setOpen({five: true})} trigger={<Label isOpen={open['five']}>hur länge varar effekten?</Label>}>
            <TreatmentDescription>
              De skadade hårsäckarna bildar inget nytt hår, men det finns en möjlighet att nya hårsäckar kan utvecklas med tiden. I genomsnitt förväntas hårstråna vara borta under en period på mellan 9 och 24 månader, varefter individuella underhållsbehandlingar kan behövas för att bibehålla det önskade resultatet.
            </TreatmentDescription>
          </Collapsible>
          <Collapsible onClose={() => setOpen({six: false})} onOpen={() => setOpen({six: true})} trigger={<Label isOpen={open['six']}>Hjälper laser mot "smultronben"?</Label>}>
            <TreatmentDescription>
              Ja, "Strawberry legs" är en term för de mörka och rödaktiga fläckarna som ofta ses på benen och överarmarna. Frekvent användning av skrapor kan orsaka ytterligare fläckar och irritation. Användningen av laser kan dock bidra till att minska detta, eftersom laserbehandling gradvis minskar behovet av frekvent skrapning, vilket kan resultera i ett förbättrat hudtillstånd.
            </TreatmentDescription>
          </Collapsible>
          <Collapsible onClose={() => setOpen({seven: false})} onOpen={() => setOpen({seven: true})} trigger={<Label isOpen={open['seven']}>Kan jag träna och använda smink efter min behandling?</Label>}>
            <TreatmentDescription>
              Vi rekommenderar att vänta till dagen efter en behandling innan du återupptar träningen. Dessutom rekommenderar vi att du avstår från att använda smink på det behandlade området för att lugna huden och undvika bakterier i de öppna porerna. Detta hjälper till att bibehålla ett hälsosamt och skyddat hudtillstånd efter behandlingen.
            </TreatmentDescription>
          </Collapsible>
          <Collapsible onClose={() => setOpen({eight: false})} onOpen={() => setOpen({eight: true})} trigger={<Label isOpen={open['eight']}>Kan jag få permanent hårborttagning på hela kroppen?</Label>}>
            <TreatmentDescription>
              Ja, laserbehandlingar kan användas på hela kroppen. Det är varken mer eller mindre farligt att få permanent hårborttagning utförd på ömtåliga eller känsliga områden. Användningen av hårborttagning med laser är i allmänhet säker och resultaten kan uppnås på olika delar av kroppen med lämplig och professionell användning av tekniken.
            </TreatmentDescription>
          </Collapsible>
          <Collapsible onClose={() => setOpen({nine: false})} onOpen={() => setOpen({nine: true})} trigger={<Label isOpen={open['nine']}>Vilka är fördelarna med permanent hårborttagning?</Label>}>
            <TreatmentDescription>
              Fördelarna med permanent hårborttagning inkluderar:

              <ul>
                <li><b>Rörelsefrihet</b>: Oroa dig inte för rakning, vaxning etc.</li>
                <li><b>Hög effektivitet</b>: Anses vara den mest effektiva metoden för hårborttagning.</li>
                <li><b>Mindre smärtsamt</b>: Generellt mindre smärtsamt än metoder som vaxning.</li>
                <li><b>Undvik röda knölar och klåda</b>: Minska risken för röda knölar och klåda som ofta kan uppstå vid rakning.</li>
                <li><b>Hälsosamt alternativ</b>: Permanent hårborttagning upplevs som ett hälsosammare alternativ jämfört med att använda ohälsosamma hårborttagningskrämer.</li>
              </ul>
            </TreatmentDescription>
          </Collapsible>
        </div>
      </TreatmentContainer>
      <TreatmentContainer p='50px 25% 0' bg={COLORS.brownRGBA2}>
        <div>
          <h2>Hur görs permanent hårborttagning?</h2>
          <TreatmentDescription>
            Med permanent hårborttagning använder vi marknadens ledande laserteknologi. Ljusstrålens exakta våglängd riktas mot pigmentet i hårsäckarna, vilket gör att laserljuset kan passera genom hudens yta och precis träffa hårets pigment (melanin). 
          </TreatmentDescription>
          <TreatmentDescription>
            Detta resulterar i förstörelse av hårsäcken. Våra laserbehandlingar är skonsamma och skadar inte den omgivande huden, vilket gör behandlingen bekväm utan behov av återhämtningstid. 
          </TreatmentDescription>
          <TreatmentDescription>
            Vi använder uteslutande den senaste och mest effektiva tekniken inom laserhårborttagning, inklusive SHR och Diode. Som en av få kliniker i Norden som erbjuder flera typer av teknologi, är vårt engagemang tydligt i att leverera skräddarsydda och avancerade lösningar.
          </TreatmentDescription>
          <h3>Lär dig mer om behandlingen nedan</h3>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
          <img src={beforeAfterImg} style={{width: '100%'}} />
          <i>Före efter bild redan efter 2 behandlingar</i>
        </div>

      </TreatmentContainer>
      <TreatmentContainer p='0 25% 50px' bg={COLORS.brownRGBA2}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
        <Collapsible onClose={() => setOpen({eleven: false})} onOpen={() => setOpen({eleven: true})} trigger={<Label isOpen={open['eleven']}>Innan din behandling</Label>}>
          <TreatmentDescription>
            För att förbereda för laserbehandling rekommenderas att raka med en rakhyvel alla de områden där du önskar få laserbehandling utförd. Ta inte bort hårstrån i behandlingsområdet med pincett eller vax innan behandlingen, eftersom det måste finnas hår strax under hudytan för att leda ljuset in i hårsäcken.
          </TreatmentDescription>
          <TreatmentDescription>
            Undvik även att använda kräm, deodorant eller andra produkter på området i fråga innan behandlingen.
          </TreatmentDescription>
          <TreatmentDescription>
            Det är viktigt att din hud inte har utsatts för sol, brun utan sol eller solarium minst 1 vecka innan din permanenta hårborttagningsbehandling. Detta är viktigt för att säkerställa att din hud inte är solbränd eller mörkare än ditt hår, vilket är viktigt för optimala behandlingsresultat. Vidare är risken för biverkningar som t.ex. pigmenteringsdefekter genom att undvika solexponering före behandling.
          </TreatmentDescription>
        </Collapsible>
        <Collapsible onClose={() => setOpen({twelve: false})} onOpen={() => setOpen({twelve: true})} trigger={<Label isOpen={open['twelve']}>Efter din behandling</Label>}>
          <TreatmentDescription>
            Generellt är det viktigt att låta huden vila efter en behandling. Om du till exempel har fått behandling i ansiktet bör du avstå från att sminka huden inom 24 timmar efter behandlingen.
          </TreatmentDescription>
          <TreatmentDescription>
            Undvik att utsätta din hud för sol, solarium eller solarium i minst 48 timmar efter behandlingen. Det rekommenderas även att använda solskyddsmedel med minsta faktor 30 vid solbad för att skydda huden.
          </TreatmentDescription>
        </Collapsible>
        <Collapsible onClose={() => setOpen({thirteen: false})} onOpen={() => setOpen({thirteen: true})} trigger={<Label isOpen={open['thirteen']}>Biverkningar</Label>}>
          <TreatmentDescription>
            <b>Vissa patienter kan uppleva följande biverkningar:</b>
          </TreatmentDescription>
          <TreatmentDescription>
            Huden kan bli röd och varm direkt efter behandlingen och det kan kännas som en form av solbränna.
          </TreatmentDescription>
          <TreatmentDescription>
            Vissa patienter kan uppleva svullnad av huden, vilket kan vara från 2 timmar upp till 3 dagar.
          </TreatmentDescription>
          <TreatmentDescription>
            Pigmentationsstörningar kan förekomma i de behandlade områdena, där områdena kan bli ljusare eller mörkare än den omgivande huden. Risken är störst om du redan har en mörk hudfärg. Pigmentationsrubbningar är svåra att behandla, men försvinner ofta över månader till år.
          </TreatmentDescription>
          <TreatmentDescription>
            Mycket sällan kan paradoxalt ökad hårväxt observeras i det behandlade området, särskilt hos personer med mörkare hudtyper.
          </TreatmentDescription>
          <TreatmentDescription>
            Risken för biverkningar ökar om du har utsatts för solljus strax före eller efter behandlingen de första månaderna.
          </TreatmentDescription>
          <TreatmentDescription>
            <b>Extremt sällan kan följande inträffa:</b>
          </TreatmentDescription>
          <TreatmentDescription>
            Brännande med bildandet av sår efter behandlingen, och i de svåraste fallen kan permanent ärrvävnad uppstå i det behandlade området. Detta inträffar dock ytterst sällan.
          </TreatmentDescription>
        </Collapsible>
        </div>
      </TreatmentContainer>
      <TreatmentContainer>
        <div>
          <TreatmentTitle>Vi är specialister på permanent hårborttagning</TreatmentTitle>
          <TreatmentDescription>
            Den främsta anledningen till att du väljer Sax and the City Skönhet som din behandling för permanent hårborttagning beror på vårt grundliga tillvägagångssätt och ständiga kunskapsförbättringar. Detta säkerställer att du alltid får den mest effektiva behandlingen inom laserbehandling för permanent hårborttagning.
          </TreatmentDescription>
          <TreatmentDescription>
            Vi prioriterar våra kunders nöjdhet, trygghet och behandlingsresultat högt. Det är därför vi bara har erfarna terapeuter som är certifierade för att utföra alla behandlingar. Detta resulterar i märkbara resultat efter kort tid för våra kunder.
          </TreatmentDescription>
          <TreatmentDescription>
            Detta engagemang har lett till en stor tillströmning av nya kunder såväl som återkommande kunder som tidigare har behandlats på annat håll.
          </TreatmentDescription> 
        </div>
        <div style={{width: '100%'}}>
          <img src={pic01} style={{width: '100%'}} />
        </div>
      </TreatmentContainer>
    </div>
  );
};

export default HairRemoval;
