import React from 'react';
import {ROUTES} from '../../constants';
import {useHistory} from 'react-router-dom';
import {
  BookContainer,
  Profile,
  ProfileContact,
  Profiles
} from './styled';
import {COLORS} from '../../constants';
import {BookButton, LogoWrapper} from '../../styled';
import LOGO from '../../assets/logowhite.png';
import Slider from 'react-slick';
import styled from 'styled-components';
import startImage2 from '../../assets/images/new/IMG_9059.jpeg';
import b1 from '../../assets/images/new/booking1.png';
import b2 from '../../assets/images/new/booking2.png';
import startImage3 from '../../assets/slideshow/img01.jpg';

const settings = {
  infinite: true,
  speed: 800,
  autoplaySpeed: 5000,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  draggable: false,
};
export const StyledSlider = styled(Slider)`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 61vh;
  
  .slick-slide {
    display: flex;
    justify-content: center;
  }
  
  img, div {
    width: 100vw;
    height: 100%;
  }
  
  img {
    objectFit: cover !important;
    opacity: 1;
    filter: brightness(80%);
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.75);
  }
  
  div {
    //border-bottom: 2px solid #111;
  }


  &:hover {
    transition: all 0.2s;
  }

  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    height: 100vh;
    img, div {
      width: 100vw;
      height: 85%;
    }
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) and (orientation : landscape) {
    height: 100vh;
    img, div {
      width: 100vw;
      height: 100%;
    }
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    height: 98vh;
    img, div {
      width: 100vw;
      height: 85%;
    }
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    height: 100vh;
    img, div {
      width: 100vw;
      height: 100%;
    }
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    height: 100vh;
    img, div {
      width: 100vw;
      height: 100%;
    }
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    height: 100vh;
    img, div {
      width: 100vw;
      height: 85%;
    }
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
    img, div {
      width: 100vw;
      height: 100%;
    }
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
    img, div {
      width: 100vw;
      height: 100%;
    }
  }
`;

const SliderWrapper = styled.div`
  margin-top: 100px;
  position: relative;
  width: 100%;
  height: 50vh;
  background: COLORS.black;

  // media queries
  
  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    height: 30vh;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) and (orientation : landscape) {

    height: 30vh;
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    height: 30vh;

  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    height: 33vh;

  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    height: 33vh;

  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    height: 33vh;

  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {

  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {

  }
`;

const Img = styled.img`
  width: 65%;
  border-radius: 5px;
  margin: 0 auto 0;

  // media queries
//  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: 90%;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) and (orientation : landscape) {
    width: 90%;
  }
 
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {

  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {

  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {

  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {

  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {

  }
`;

const Book = () => {
  const history = useHistory();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderSlider = () => {
    return <StyledSlider {...settings}>
      <img src={startImage2} alt=""/>
      <img src={startImage3} alt=""/>
    </StyledSlider>;
  };

  const renderHeader = () => (
    <div style={{position: 'relative', display: 'flex', justifyContent: 'center'}}>
      <SliderWrapper>
        {renderSlider()}
      </SliderWrapper>
      <LogoWrapper>
        <img src={LOGO} style={{width: '50%'}} />
        <BookButton onClick={() => history.push(ROUTES.bookings)}>Boka nu</BookButton>
      </LogoWrapper>
    </div>
  );
  const renderProfiles = () => (
    <Profiles>
      <Profile>
        <img
          src={b2}
          alt="Profile"
          style={{width: '70%', maxWidth: '600px', borderRadius: '5px', margin: '0 auto 0'}}
        />
        <ProfileContact>
          <BookButton bg={COLORS.brown2} link onClick={() => window.location.href = 'https://www.bokadirekt.se/places/sax-and-the-city-24548'}>Boka nu</BookButton>
        </ProfileContact>
      </Profile>
      <Profile>
        <img
          src={b1}
          alt="Profile"
          style={{width: '70%', maxWidth: '600px', borderRadius: '5px', margin: '0 auto 0'}}
        />
        <ProfileContact>
          <BookButton bg={COLORS.brown2} link onClick={() => window.location.href = 'https://www.bokadirekt.se/places/sax-and-the-city-skonhet-57239'}>Boka nu</BookButton>
        </ProfileContact>
      </Profile>
    </Profiles>
  );

  const renderBook = () => (
    <BookContainer>
      {renderHeader()}
      {renderProfiles()}
    </BookContainer>
  );

  return renderBook();
};

export default Book;
