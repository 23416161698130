import React from 'react';
import {withRouter} from 'react-router-dom';
import LOGO from '../../assets/logo.png';
import {Link, Links, LinkDropdown, LinkInDropdown, Social, StyledBar, Img, Trigger, BarTitle} from './styled';
import * as Icon from 'react-feather';
import {ROUTES, COLORS} from '../../constants';

const Bar = ({history}) => {
  const [showMenu, setShowMenu] = React.useState(false);
  const [showTreatmentDropdown, setShowTreatmentDropdown] = React.useState(false);
  const goTo = route => {
    history.push(route);
    setShowMenu(false);
  };

  return <>
    <StyledBar showMenu={showMenu} onMouseLeave={() => setShowTreatmentDropdown(false)}>
      <Trigger onClick={() => setShowMenu(!showMenu)} showMenu={showMenu}>
        <Icon.Menu size={78} />
      </Trigger>

      <BarTitle
        showMenu={showMenu}
        onClick={() => window.location.href = 'http://saxandthecity.se'}
      >
        <Img src={LOGO} />
      </BarTitle>
      
      <Links showMenu={showMenu}>
        <Link onClick={() => goTo(ROUTES.start)}>Hem</Link>
        <Link onClick={() => goTo(ROUTES.bookings)}>Boka</Link>
        <Link onClick={() => goTo(ROUTES.treatments)} onMouseOver={() => !showMenu && setShowTreatmentDropdown(true)}>Behandlingar</Link>
        {(showTreatmentDropdown || window.innerWidth < 1000) && (
          <LinkDropdown onMouseOver={() => !showMenu && setShowTreatmentDropdown(true)} onMouseLeave={() => setShowTreatmentDropdown(false)}>
            <LinkInDropdown onClick={() => goTo(ROUTES.acne)}>Acne</LinkInDropdown>
            <LinkInDropdown onClick={() => goTo(ROUTES.insculptChair)}>Bäckenbottenträning</LinkInDropdown>
            <LinkInDropdown onClick={() => goTo(ROUTES.stretchMarks)}>Bristningar</LinkInDropdown>
            <LinkInDropdown onClick={() => goTo(ROUTES.cellulites)}>Celluliter</LinkInDropdown>
            <LinkInDropdown onClick={() => goTo(ROUTES.skinTightening)}>Hudåtstramning</LinkInDropdown>
            <LinkInDropdown onClick={() => goTo(ROUTES.hairRemoval)}>Hårborttagning</LinkInDropdown>
            <LinkInDropdown onClick={() => goTo(ROUTES.vessels)}>Kärl</LinkInDropdown>
            <LinkInDropdown onClick={() => goTo(ROUTES.pigment)}>Pigment</LinkInDropdown>
          </LinkDropdown>
        )}
        <Link onClick={() => goTo(ROUTES.contact)}>Kontakt</Link>
      </Links>
      <Social color='#4267B2' href="https://www.facebook.com/saxandthecitydockan/" target="_blank">
        <Icon.Facebook size={40}/>
      </Social>
      <Social color='#c13584' href="https://www.instagram.com/saxandthecity_dockan/" target="_blank">
        <Icon.Instagram  size={40}/>
      </Social>
    </StyledBar>
  </>;
};

export default withRouter(Bar);
