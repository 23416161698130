import React from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {ROUTES} from '../../constants';
import * as Icon from 'react-feather';
import {BookButton} from '../../styled';
import logoB from '../../assets/logo.png';
import LogoFrisor from '../../assets/logofrisorsvart.svg';
import LogoSkonhet from '../../assets/logoskonhetsvart.png';
import startImage3 from '../../assets/startimage3.jpg';
import footerImage from '../../assets/images/new/IMG_8918.jpeg';
import logoAB1 from '../../assets/logoAB1.png';
import logoAB2 from '../../assets/logoAB2.png';
import {COLORS} from '../../constants';
import {Social} from '../bar/styled';
import logoABreal1 from '../../assets/logoABreal1.jpg';
import logoABreal2 from '../../assets/logoABreal2.png';

const SponsorWrapper = styled.div`
  z-index: 9999;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: ${COLORS.brown};
  padding: 0 0 20px 0;
  box-sizing: border-box;
  color: ${COLORS.white};
  
  img {
    width: 100px;
    height: auto;
    margin: 0 20px;
    border-radius: 5px;
  }
`;

const Slogan = styled.div`
  z-index: 100;
  width: 100%;
  min-height: 230px;
  color: ${COLORS.brown2};
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1.0em;
  background: ${COLORS.brown};
  padding: 0 20px;
  box-sizing: border-box;


  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: 100%;
    font-size: 0.7em;
    div {
      flex-direction: column;
      align-items: center;
    }

    img {
      width: 100px;
      height: auto !important;
    }
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) and (orientation : landscape) {
    width: 100%;
    min-height: 240px;
    font-size: 0.7em;
    div {
      flex-direction: column;
      align-items: center;
    }

    img {
      width: 100px;
      height: auto !important;
    }
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    width: 100%;
    min-height: 210px;
    font-size: 0.7em;
    div {
      flex-direction: column;
      align-items: center;
    }

    img {
      width: 100px;
      height: auto !important;
    }
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    font-size: 1.0em;
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    font-size: 1.0em;
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    font-size: 1.0em;
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
    font-size: 1.0em;
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
    font-size: 1.0em;
  }
`;

const SloganItem = styled.div`
  display: flex; 
  margin: 25px 5px 20px;
  justify-content: space-between;
  width: 500px;
  color: ${COLORS.black};

  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: 100%;
    flex-direction: ${({flexDir}) => flexDir || 'column'} !important;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) and (orientation : landscape) {
    width: 100%;
    flex-direction: ${({flexDir}) => flexDir || 'column'} !important;
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    width: 100%;
    flex-direction: ${({flexDir}) => flexDir || 'column'} !important;
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLORS.white};
`;

const FooterBookContainer = styled.div`
  z-index: 99999;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%; 
  h1 {
    font-size: 4.5em;
  }

  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    height: auto;
    margin-top: 150px;
    h1 {
      font-size: 1em;
    }
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) and (orientation : landscape) {
    height: auto;
    margin-top: 150px;
    h1 {
      font-size: 1em;
    }
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    height: auto;
    margin-top: 150px;
    h1 {
      font-size: 1em;
    }
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    height: auto;
    margin-top: 150px;
    h1 {
      font-size: 1em;
    }
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    height: auto;
    margin-top: 150px;
    h1 {
      font-size: 1em;
    }
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    height: auto;
    margin-top: 150px;
    h1 {
      font-size: 1em;
    }
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
  }

`;


const Footer = () => {
  const router = useHistory();
  
  return (
    <FooterWrapper>
      <img src={footerImage} style={{width: '100%', height: '600px', objectFit: 'cover', zIndex: '99999', filter: 'brightness(80%)'}} alt=""/>
      <FooterBookContainer>
        <h1>
          Boka din tid idag!
        </h1>
        <BookButton onClick={() => {
          if(router.location.pathname === ROUTES.bookings) {
            window.scrollTo(0, 0);
            return;
          }
          router.push(ROUTES.bookings);
        }}>Boka nu</BookButton>
      </FooterBookContainer>
      <Slogan>
        <SloganItem>
          <img src={LogoFrisor} style={{height: '50px'}} alt=""/>
          <div style={{display: 'flex', margin: '5px', flexDirection: 'column'}}>
            <div style={{display: 'flex'}}>040-611 1 611</div>
            <div style={{display: 'flex'}}>Isbergs gata 8B</div>
            <div style={{display: 'flex'}}>21119 Malmö</div>
          </div>
          <Social color='#4267b2' href="https://www.facebook.com/saxandthecitydockan/" target="_blank">
            <Icon.Facebook size={40}/>
          </Social>
        </SloganItem>
        <SloganItem flexDir='column-reverse'>
          <Social color='#c13584' href="https://www.instagram.com/saxandthecity_dockan/" target="_blank">
            <Icon.Instagram size={40}/>
          </Social>
          <div style={{display: 'flex', margin: '5px', flexDirection: 'column'}}>
            <div style={{display: 'flex'}}>076-856 60 17</div>
            <div style={{display: 'flex'}}>Isbergs gata 10C</div>
            <div style={{display: 'flex'}}>21119 Malmö</div>
          </div>
          <img src={LogoSkonhet} style={{height: '50px'}} alt=""/>
        </SloganItem>
      </Slogan>
      <SponsorWrapper>
        <img src={logoABreal1} alt=""/>
        <img src={logoABreal2} alt=""/>
      </SponsorWrapper>
    </FooterWrapper>
  );
};

export default Footer;
