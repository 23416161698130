import React from 'react';
import {useHistory} from 'react-router-dom';
import {COLORS, ROUTES} from '../../constants';
import LOGO from '../../assets/logowhite.png';
import img01 from '../../assets/images/new/IMG_9702.jpeg';
import {BookButton} from '../../styled';
import styled from 'styled-components';
import bristning1 from '../../assets/images/bristning1.png';
import bristning2 from '../../assets/images/bristning2.png';
import bristning3 from '../../assets/images/bristning3.png';
import Collapsible from 'react-collapsible';

import {
  TreatmentContainer,
  TreatmentTitle,
  TreatmentDescription,
  LogoBookButtonWrapper,
} from '../../styled';

const CollapsibleLabel = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.2em;
  padding: 0 25px 0;
  border: 1px solid ${COLORS.gold};
  width: 100%;
  height: 50px;
  cursor: pointer;
  margin: 5px 0;
`;

const OpenLabel = styled.div`
  right: 10px;
  position: absolute;
  font-size: 1.5em;
`;

const Label = ({children, isOpen}) => {
  return (
    <CollapsibleLabel>
      {children}
      {isOpen 
        ? <OpenLabel>-</OpenLabel>
        : <OpenLabel>+</OpenLabel>
      }
    </CollapsibleLabel>
  );
};

const StretchMarks = () => {
  const history = useHistory();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [open, setOpen] = React.useState({});

  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div style={{marginTop: '100px', position: 'relative', width: '100%', height: '50vh', background: COLORS.black}}>
          <img
            src={img01} alt="Image of table with Sax and the City text"
            style={{width: '100%', height: '100%', objectFit: 'cover', filter: 'brightness(80%)'}}
          />
        </div>
        <LogoBookButtonWrapper>
          <img src={LOGO} style={{width: '50%'}} />
          <BookButton onClick={() => history.push(ROUTES.bookings)}>Boka nu</BookButton>
        </LogoBookButtonWrapper>
      </div>
      <TreatmentContainer>
        <div>
          <TreatmentTitle>Borttagning av bristningar Malmö</TreatmentTitle>
          <TreatmentDescription>
            Den vetenskapligt dokumenterade och skonsamma behandlingsmetoden som vi erbjuder minskar bristningar med upp till 80 % redan efter första behandlingen.
          </TreatmentDescription>
          <TreatmentDescription>
            Synliga resultat uppnås redan med den första behandlingen. Bristningar, som ofta stör många människor, särskilt efter graviditet, samt hos överviktiga personer, ungdomar och personer som snabbt går ner i vikt, kan visa sig som röda, bruna, lila eller genomskinliga ränder på huden.
          </TreatmentDescription>
          <TreatmentDescription>
            Den vetenskapligt dokumenterade behandlingsmetoden implementeras på över 14 000 kliniker globalt och bygger på resultat från mer än 400 000 behandlingar. Denna omfattande testning och dokumentation bekräftar metoden som marknadens mest effektiva för behandling av hudbristningar.
          </TreatmentDescription>
        </div>
      </TreatmentContainer>
      <TreatmentContainer bg={COLORS.brownRGBA2}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <TreatmentDescription>
            <h3>Hur uppstår bristningar?</h3>
          </TreatmentDescription>
          <TreatmentDescription>
            Bristningar uppstår till följd av snabb och kraftig sträckning av huden, typiskt i samband med snabb viktökning, intensiv muskeltillväxt, snabb viktminskning eller under tillväxtperioden hos unga under puberteten.
          </TreatmentDescription>
          <TreatmentDescription>
            Dessa märken representerar skador på det elastiska mellanskiktet av huden, som kallas dermis eller dermis. Dermis är det tjockaste hudlagret och när det spricker bildas bristningar.
          </TreatmentDescription>
          <TreatmentDescription>
            När ett bristningsmärke dyker upp, visas det ofta som lila, rosa eller blå ränder i huden. Bristningar försvinner inte av sig själva, men med tiden kan de blekna och bli ljusare i färgen.
          </TreatmentDescription>
        </div>
      </TreatmentContainer>
      <TreatmentContainer>
        <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
          <img src={bristning2} alt="Image of table with Sax and the City text" style={{width: '100%', objectFit: 'cover', borderRadius: '5px'}} />
        </div>
        <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
          <TreatmentDescription>
            <h3>Hur fungerar behandlingen?</h3>
          </TreatmentDescription>
          <TreatmentDescription>
            Vakuum-behandlingen är mycket skonsam och effektiv. Kärnan i behandlingen är att stimulera huden att bilda nya, starka kollagenceller, som bidrar till ökad tillväxt, spänst och fyllighet i huden. Detta uppnås genom vakuum som drar upp blodet till det område av huden som ska behandlas. På så sätt tillförs mer syre och näringsämnen till området. Denna stimulering främjar aktiviteten och tillväxten i fibroblastcellerna, och processen stöds av hembehandlingen.
          </TreatmentDescription>
          <TreatmentDescription>
            Hembehandlingen består av ett särskilt utvecklat kit med produkter som innehåller fetter, vitaminer, mineraler och viktigast av allt proteiner. Hembehandlingen är medicinskt utvecklad och är avgörande för att upprätthålla och stärka behandlingsresultaten.
          </TreatmentDescription>
          <TreatmentDescription>
            När ett bristningsmärke dyker upp, visas det ofta som lila, rosa eller blå ränder i huden. Bristningar försvinner inte av sig själva, men med tiden kan de blekna och bli ljusare i färgen.
          </TreatmentDescription>
        </div>
      </TreatmentContainer>
      <TreatmentContainer p='50px 25% 0' bg={COLORS.brownRGBA2}>
        <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
          <img src={bristning1} alt="Image of table with Sax and the City text" style={{width: '100%', objectFit: 'cover', borderRadius: '5px'}} />
        </div>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <TreatmentDescription>
            <h3>Mer om behandlingen</h3>
          </TreatmentDescription>
          <TreatmentDescription>
            Vid Vakuum-metoden används varken nålar, laser, kirurgi eller injektioner. Behandlingen utförs med ett kraftigt vakuumsug. Det uppstår varken ärr, sår, skorpor eller blod, och det finns ingen efterföljande smärta. Eventuell rodnad och ömhet kan förekomma i upp till 24 timmar efter behandlingen.
          </TreatmentDescription>
        </div>
      </TreatmentContainer>
      <TreatmentContainer p='0 25% 50px' bg={COLORS.brownRGBA2}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Collapsible onClose={() => setOpen({one: false})} onOpen={() => setOpen({one: true})} trigger={<Label isOpen={open['one']}>Före din behandling</Label>}>
            <TreatmentDescription>
              Innan din behandling vill vi bjuda in dig till en gratis konsultation. Om du bor långt bort kan detta möte även hållas per telefon.
              Observera att du bör undvika solljus veckan innan din behandling, och vi rekommenderar att du dricker rikligt med vatten.
              Vid själva behandlingen rekommenderas det att bära löst sittande kläder och undvika elastiska band över det område som ska behandlas.
            </TreatmentDescription>
          </Collapsible>
          <Collapsible onClose={() => setOpen({two: false})} onOpen={() => setOpen({two: true})} trigger={<Label isOpen={open['two']}>Under din behandling</Label>}>
            <TreatmentDescription>
              <h3>Behandlingsprocessen för vakuum är som följer:</h3>
            </TreatmentDescription>
            <TreatmentDescription>
              <b>Förberedelse</b>: Huden på det område som ska behandlas desinficeras först. Därefter appliceras en värmegel för att öka blodcirkulationen till det behandlade området.
            </TreatmentDescription>
            <TreatmentDescription>
              <b>Aktivering</b>: Efter 10 minuter appliceras ett serum som aktiverar huden och främjar vävnadens regenerering.
            </TreatmentDescription>
            <TreatmentDescription>
              <b>Vakuumbehandling</b>: Vakuumsuget används på dina bristningar, och maskinen körs fram och tillbaka över varje sträckmärke. Detta drar upp blod till de drabbade hudområdena.
            </TreatmentDescription>
            <TreatmentDescription>
              <b>Inpackning</b>: Efter avslutad behandling förpackas området med folie som ska sitta på i cirka 2 timmar.
            </TreatmentDescription>
            <TreatmentDescription>
              <b>Uppföljning</b>: Efter 15 dagar kan du komma in för en stimulerande behandling som tar endast 15 minuter och är inkluderad i priset. Under denna behandling desinficeras huden, och specialdroppar samt uppstramande serum appliceras. Området förpackas återigen med folie som ska sitta på i cirka 2 timmar.
            </TreatmentDescription>
          </Collapsible>
          <Collapsible onClose={() => setOpen({three: false})} onOpen={() => setOpen({three: true})} trigger={<Label isOpen={open['three']}>Efter din behandling</Label>}>
            <TreatmentDescription>
              Efter 2 timmar tas folien bort, och hemma-behandlingssättet får användas tidigast efter 8 timmar. Inga andra produkter får användas på det behandlade området.
            </TreatmentDescription>
            <TreatmentDescription>
              Undvik solning på området i 30 dagar.
            </TreatmentDescription>
            <TreatmentDescription>
              Undvik att utsätta området för sol, bastu, klorvatten och intensiv träning såsom bodybuilding, spinning och löpning under de följande 14 dagarna efter din behandling. Måttlig aktivitet som yoga, cykling eller promenader är tillåtet.
            </TreatmentDescription>
            <TreatmentDescription>
              De röda strecken försvinner vanligtvis efter 7-14 dagar. Undvik att klia på området, då huden inte arbetar symmetriskt och ett sträckmärke kan se annorlunda ut.
            </TreatmentDescription>
            <TreatmentDescription>
              Drick rikligt med vatten, cirka 2 liter dagligen rekommenderas.
            </TreatmentDescription>
            <TreatmentDescription>
              21 dagar efter sista behandlingen kan du börja använda underhållsprodukter för att bevara hudens elasticitet.
            </TreatmentDescription>
            <TreatmentDescription>
              Om du önskar en ytterligare behandling (max 3) kan den göras 30 dagar efter den senaste behandlingen.
            </TreatmentDescription>
            <TreatmentDescription>
              Använd SOS-cremen i ditt hem-kit vid svullnad och stark klåda,
            </TreatmentDescription>
          </Collapsible>
        </div>
      </TreatmentContainer>
      <TreatmentContainer>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <img src={bristning3} alt="Image of table with Sax and the City text" style={{width: '100%', objectFit: 'cover', borderRadius: '5px'}} />
        </div>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <TreatmentDescription>
            <h3>Hembehandlingskit ingår i priset</h3>
          </TreatmentDescription>
          <TreatmentDescription>
            Hembehandlingsprodukterna består av 5 olika vårdprodukter, som innehåller fetter, vitaminer, mineraler och viktigast av allt protein, och som är medicinskt utvecklade. Dessa produkter är både färg- och parfymfria, men innehåller aktiva ämnen som bidrar till läkning och förbättring av sträckmärken.
          </TreatmentDescription>
          <TreatmentDescription>
             Det är avgörande att du använder alla fem vårdsprodukter på det behandlade området två gånger dagligen under de första 21 dagarna efter din behandling för att uppnå bästa behandlingsresultat.
          </TreatmentDescription>
          <TreatmentDescription>
            Resultaten är långvariga, men det kan finnas risk för återkomst vid hormonella förändringar eller viktökning.
          </TreatmentDescription>
        </div>
      </TreatmentContainer>
    </div>
  );
};

export default StretchMarks;
