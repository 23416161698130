import React from 'react';
import Slider from 'react-slick';
import {useHistory} from 'react-router-dom';
import LOGO from '../../assets/logowhite.png';
import {BookButton, LogoWrapper} from '../../styled';
import {StartContainer} from './styled';
import {COLORS} from '../../constants';
import styled from 'styled-components';
import {ROUTES} from '../../constants';

import startImage from '../../assets/startimage2.jpg';
import startImage2 from '../../assets/images/new/IMG_9059.jpeg';
import startImage3 from '../../assets/slideshow/img01.jpg';
import logoAB1 from '../../assets/logoAB1.png';
import logoAB2 from '../../assets/logoAB2.png';

const settings = {
  infinite: true,
  speed: 800,
  autoplaySpeed: 5000,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  draggable: false,
};
export const StyledSlider = styled(Slider)`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  
  .slick-slide {
    display: flex;
    justify-content: center;
  }
  
  img, div {
    width: 100vw;
    height: 100%;
  }
  
  img {
    opacity: 1;
    filter: brightness(80%);
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.75);
  }
  
  div {
    //border-bottom: 2px solid #111;
  }


  &:hover {
    transition: all 0.2s;
  }

  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    img, div {
      width: 100vw;
      height: 85%;
    }
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) and (orientation : landscape) {
    img, div {
      width: 100vw;
      height: 100%;
    }
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    img, div {
      width: 100vw;
      height: 85%;
    }
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    img, div {
      width: 100vw;
      height: 100%;
    }
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    img, div {
      width: 100vw;
      height: 100%;
    }
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    img, div {
      width: 100vw;
      height: 85%;
    }
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
    img, div {
      width: 100vw;
      height: 100%;
    }
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
    img, div {
      width: 100vw;
      height: 100%;
    }
  }
`;

const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem;
  padding: 50px 20px 100px;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: ${COLORS.white};
  margin-bottom: 1rem;
`;

const Subtitle = styled.h2`
  font-size: 1.8rem;
  color: ${COLORS.white};
  margin-bottom: 1.5rem;
`;

const Text = styled.p`
  font-size: 1.2rem;
  color: ${COLORS.white};
  margin-bottom: 1rem;
  max-width: 800px;
`;

const WelcomeMessage = () => {
  const router = useHistory();
  return (
    <WelcomeContainer>
      <Title>Välkommen till Sax and the City i Malmö!</Title>
      <Text>
        Vi är glada att välkomna dig till våra två exklusiva salonger som ligger sida vid sida på dockan – vår frisörsalong och vår skönhetssalong. Här på Sax and the City erbjuder vi en helhetsupplevelse för dig som vill unna dig det bästa inom hårvård och skönhet.
      </Text>
      <Subtitle>Frisörsalongen</Subtitle>
      <Text>
        Hos oss möts du av passionerade och erfarna frisörer som är här för att lyssna på dina önskemål och förvandla ditt hår till något unikt och personligt. Vi erbjuder allt från klippning och färgning till avancerade hårbehandlingar, allt med noggrant utvalda produkter som ger ditt hår den kärlek det förtjänar. Låt oss hjälpa dig att framhäva din bästa sida – boka din tid idag och upplev skillnaden hos oss!
      </Text>
      <Subtitle>Skönhetssalongen</Subtitle>
      <Text>
        I vår moderna skönhetssalong, där vi kombinerar expertis och avancerade behandlingar för att främja din välmående och skönhet. Vi erbjuder specialiserad bäckenbottenträning för att stärka musklerna och förbättra din hälsa. Våra laserbehandlingar är skonsamma och effektiva för hudförbättring och hårborttagning.
      </Text>
      <Text>
        För att reducera synliga bristningar använder vi innovativa metoder som stimulerar hudens återhämtning och elasticitet. Dessutom erbjuder vi avancerade cellulitbehandlingar som jämnar ut hudens struktur och ger en slätare look. Hos oss får du en personlig och professionell upplevelse i en avslappnande miljö, där ditt välbefinnande står i centrum.
      </Text>
      <Text>
        <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => router.push(ROUTES.bookings)}>Boka din tid idag</span> och upplev varför Sax and the City är den främsta destinationen för skönhet och välbefinnande. Vi ser fram emot att få ta hand om dig!
      </Text>
        <div style={{display: 'flex', height: '100px', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
          <img src={logoAB1} style={{height: '50px', margin: '5px', borderRadius: '5px'}} />
          <img src={logoAB2} style={{height: '50px', margin: '5px', borderRadius: '5px'}} />
        </div>
    </WelcomeContainer>
  );
};

const SliderWrapper = styled.div`
  margin-top: 100px;
  position: relative;
  width: 100%;
  height: 100vh;
  background: COLORS.black;

  // media queries
  
  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    height: 29vh;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) and (orientation : landscape) {

    height: 29vh;
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    height: 29vh;

  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    height: 33vh;

  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    height: 33vh;

  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    height: 33vh;

  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {

  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {

  }
`;

const Home = props => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderSlider = () => {

    return <StyledSlider {...settings}>
      <img src={startImage3} alt=""/>
      <img src={startImage2} alt=""/>
    </StyledSlider>;
  };
  return (
    <StartContainer>
      <SliderWrapper>
        {renderSlider()}
      </SliderWrapper>
      <LogoWrapper>
        <img src={LOGO} style={{width: '50%'}} />
        <BookButton onClick={() => props.history.push(ROUTES.bookings)}>Boka nu</BookButton>
      </LogoWrapper>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          backgroundColor: COLORS.brown,
        }}
      >
        <WelcomeMessage />
      </div>
    </StartContainer>
  );
};

export default Home;
