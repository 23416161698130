import React from 'react';
import {ContactContainer, ContactInfo, ContactMap, ContactCont} from './styled';
import {ROUTES} from '../../constants';
import {Social} from '../../components/bar/styled';
import Logo from '../../components/logo';
import * as Icon from 'react-feather';
import {useHistory} from 'react-router-dom';
import {COLORS} from '../../constants';
import {BookButton, LogoWrapper} from '../../styled';
import img01 from '../../assets/slideshow/img01.jpg';
import LOGO from '../../assets/logowhite.png';
import Slider from 'react-slick';
import styled from 'styled-components';
import startImage2 from '../../assets/images/new/IMG_9059.jpeg';
import startImage3 from '../../assets/slideshow/img01.jpg';
import logoAB1 from '../../assets/logoAB1.png';
import logoAB2 from '../../assets/logoAB2.png';

const settings = {
  infinite: true,
  speed: 800,
  autoplaySpeed: 5000,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  draggable: false,
};
export const StyledSlider = styled(Slider)`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 61vh;
  
  .slick-slide {
    display: flex;
    justify-content: center;
  }
  
  img, div {
    width: 100vw;
    height: 100%;
  }
  
  img {
    objectFit: cover !important;
    opacity: 1;
    filter: brightness(80%);
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.75);
  }
  
  div {
    //border-bottom: 2px solid #111;
  }


  &:hover {
    transition: all 0.2s;
  }

  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    height: 100vh;
    img, div {
      width: 100vw;
      height: 85%;
    }
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) and (orientation : landscape) {
    height: 100vh;
    img, div {
      width: 100vw;
      height: 100%;
    }
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    height: 98vh;
    img, div {
      width: 100vw;
      height: 85%;
    }
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    height: 100vh;
    img, div {
      width: 100vw;
      height: 100%;
    }
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    height: 100vh;
    img, div {
      width: 100vw;
      height: 100%;
    }
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    height: 100vh;
    img, div {
      width: 100vw;
      height: 85%;
    }
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
    img, div {
      width: 100vw;
      height: 100%;
    }
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
    img, div {
      width: 100vw;
      height: 100%;
    }
  }
`;

const StarterSection = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;

  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) and (orientation : landscape) {
    
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
  }
`;

const SliderWrapper = styled.div`
  margin-top: 100px;
  position: relative;
  width: 100%;
  height: 50vh;
  background: COLORS.black;

  // media queries
  
  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    height: 30vh;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) and (orientation : landscape) {

    height: 30vh;
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    height: 30vh;

  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    height: 33vh;

  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    height: 33vh;

  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    height: 33vh;

  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {

  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {

  }
`;

const TextSection = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  top: 200px;
  height: 300px;

  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    top: 100px;
    height: 200px;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) and (orientation : landscape) {
    top: 100px;
    height: 200px;
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    top: 100px;
    height: 200px;
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
  }
`;

const Marker = ({text}) => <div>{text}</div>;

const Contact = () => {
  const history = useHistory();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderSlider = () => {
    return <StyledSlider {...settings}>
      <img src={startImage2} alt=""/>
      <img src={startImage3} alt=""/>
    </StyledSlider>;
  };

  const renderLogo = () => <Logo />

  const renderHeader = () => (
    <StarterSection>
      <SliderWrapper>
        {renderSlider()}
      </SliderWrapper>
      <LogoWrapper>
        <img src={LOGO} style={{width: '50%'}} />
        <BookButton onClick={() => history.push(ROUTES.bookings)}>Boka nu</BookButton>
      </LogoWrapper>
    </StarterSection>
  );

  const renderContactInfo = () => (
    <ContactInfo>
      <div>
        <b>Sax and the City Dockan</b><br />
        <p>Isbergs gata 8b</p>
        <p>21119 Malmö</p>
        <p>Sverige</p>
      <br />
        <b>Sax and the City Skönhet</b><br />
        <p>Isbergs gata 10c</p>
        <p>21119 Malmö</p>
        <p>Sverige</p>
      </div>
      <div>
        <b>Kontakt:</b>
        <p>Telefon frisör: 040-611 1 611</p>
        <p>Telefon skönhet: 076-856 60 17</p>
        <p>E-mail: saxandthecity1@gmail.com</p>
        <p>Hemsida: www.saxandthecity.se</p>
        <div style={{display: 'flex', height: '100px'}}>
          <Social display='flex' color='#4267b2' href="https://www.facebook.com/saxandthecitydockan/" target="_blank">
            <Icon.Facebook size={40} />
          </Social>
          <Social display='flex' color='#c13584' href="https://www.instagram.com/saxandthecity_dockan/" target="_blank">
            <Icon.Instagram size={40} />
          </Social>
        </div>
      </div>
      <div>
        <b>Öppettider frisör:</b>
        <p>Måndag – Fredag: 10:00-18:00</p>
        <p>Lördag: 09:00-14:00</p>
        <br />
        <b>Öppettider skönhet:</b>
        <p>Måndag – Fredag: 10:00-18:00</p>
        <p>Lördag: 10:00-15:00</p>
        <br />
        <p>Öppettiderna kan vara avvikande. Se gärna på Boka direkt för bokningsbara tider.
Då vi efter behov har längre öppettider.</p>
      </div>
      <div>
        <b>Kollektivtrafik:</b>
        <p>
          För att komma till oss kan man enkelt ta tåg eller bus. 
        </p>
        <br />
        <p>
          Tåg till Malmö Central, därefter en kort promenad på 8min eller 
          Stadsbuss nr 3 och 5 tar er raka vägen till Dockans busshållplats på 3min. 
          200m in på Isbergs gata hittar ni Sax and the City Dockan & Sax and the city Skönhet.
        </p>
        <br />
        <p>
          Det finns gott om parkeringsplatser i området.
        </p>
      </div>
    </ContactInfo>
  );

  const renderContactMap = () => (
    <ContactMap>
      <div>
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2253.3377993177623!2d12.989038516099926!3d55.613537710191565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4653a407b8608753%3A0x4d3ce4448ca07e29!2sSax%20and%20the%20city%20Dockan!5e0!3m2!1ssv!2sse!4v1590691518029!5m2!1ssv!2sse" 
          style={{width: '100%', borderRadius: '5px', minHeight: '250px', height: '450px'}} 
          aria-hidden="false" 
          title="Sax and the City Dockan"
        >
        </iframe>
      </div>
      <div>
        <iframe 
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Sax%20and%20the%20City%20Sk%C3%B6nhet+(Sax%20and%20the%20City%20Sk%C3%B6nhet)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          style={{width: '100%', borderRadius: '5px', minHeight: '250px', height: '450px'}} 
          aria-hidden="false" 
          title="Sax and the City Skönhet"
        >
        </iframe>
      </div>
    </ContactMap>
  );

  const renderBook = () => (
    <ContactContainer>
      {renderHeader()}
      <div style={{width: '100%', background: COLORS.brown, zIndex: '9999', display: 'flex', justifyContent: 'center'}}>
      <ContactCont>
        {renderContactInfo()}
        <div style={{display: 'flex', height: '100px', width: '100%', justifyContent: 'center'}}>
          <img src={logoAB1} style={{height: '50px', margin: '5px', borderRadius: '5px'}} />
          <img src={logoAB2} style={{height: '50px', margin: '5px', borderRadius: '5px'}} />
        </div>
        {renderContactMap()}
      </ContactCont> 
      </div>
    </ContactContainer>
  );

  return renderBook();
};

export default Contact;
